<template>
  <div class="pb-150 pt-150 bg-white position-relative">
    <b-container>
      <div class="ribbon-offer-down-bg">
        <div style="line-height: 1.3" class="offer-text text-20">FRIENDS / FAMILY<br>OFFERS</div>
      </div>

      <b-row>
              <b-col cols="4">
                <InsuranceReviewForReferralCard />
              </b-col>

              <b-col cols="4">
                <MortgageReviewForReferralCard />
              </b-col>

              <b-col cols="4">
                <DiscountedWillWritingForReferralCard />
              </b-col>

            </b-row>


    </b-container>
  </div>
</template>

<script>
import DiscountedWillWritingForReferralCard from "@/views/client/dashboard/referral/cards/DiscountedWillWritingForReferralCard";
import MortgageReviewForReferralCard from "@/views/client/dashboard/referral/cards/MortgageReviewForReferralCard";
import InsuranceReviewForReferralCard from "@/views/client/dashboard/referral/cards/InsuranceReviewForReferralCard";


export default {
  name: 'OffersSection',
  components: {
    DiscountedWillWritingForReferralCard,
    MortgageReviewForReferralCard,
    InsuranceReviewForReferralCard,
  },
  props: {
    event: {
      type: Object,
      required: false
    }
  }
}
</script>