<template>
  <div class="questionnaire">

    

    <label>Name</label>

    <InputBasic
        v-model="form.name"
        :noSave="true"
        :db="db"
        :args="args"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />

    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-50">
        <label>E-mail</label>
        <InputBasic
            :value="form.email"
            @input="$emit('inputFirst', $event)"
            ref="email"
            :no-save="true"
            :db="db"
            :args="args"
            @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        />

      </div>
      <div class="question question-multipart field-50">
        <label>Phone Number</label>
        <InputBasic
            :value="form.phone_number"
            @input="$emit('inputSecond', $event)"
            ref="phone_number"
            :no-save="true"
            :db="db"
            :args="args"
            @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
        />
      </div>
    </div>


  </div>
</template>

<script>

import InputBasic from "@/components/common/questionnaires/question/inputs/InputBasic";
// import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";


export default {
  name: 'ReferralForm',
  components: {
    InputBasic,
    // QuestionBase,
  },
  props: {
    interest_type: {
      type: String,
      required: true
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data(){
    return {
      question: {
        title: 'Name',
        subTitle: null,
        tip: null
      },
      form:{
        name:null,
        email:null,
        phone_number:null,
        interest_type:this.interest_type,
      },
      db: {
        saveLocation: '',
        saveField: '',
        objectId: 0
      },
      args: {
        placeholder: 'e.g. ',
        label: null,
        skippable: false,
        required: false,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  },
}
</script>
