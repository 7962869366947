
export const helpers = {
    computed: {
        title () {
            return 'Referral'
        },
        subTitle () {
            return 'Refer a friend'
        },
    }
}
