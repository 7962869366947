<template>
  <b-col md="7">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Introduction</span>
      </h4>
    </div>
    <p style="margin-top: -30px">
      Referral information here...
    </p>
  </b-col>
</template>

<script>

export default {
  name: "Introduction",
};
</script>
