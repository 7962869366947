
<template>
  <b-card @click.stop="$bvModal.show(modalId)" class="hover-down dash-btn white"
          style="background: #321433">
    <img alt="shield"
         class="btn-background-img"
         src="@/assets/images/start-1.png">
    <div class="ribbon success ribbon-top-right">
      <span>Limited Offer</span>
    </div>
    <div class="face">
      <div class="face-top">
        <h3>50% OFF<br>Will Writing<br>Service</h3>
      </div>
      <div class="face-bottom">
        <i class="i-Tag"/>
        <span>£89.50 (Usually £179)</span>
      </div>
    </div>
    <div class="back">
      <p>Organise or review life insurance, critical illness cover or income protection. Service provided by Dunham
        McCarthy Financial Services.
      </p>
      <h3>Click to Refer</h3>
    </div>
    <b-modal :id="modalId" title="Refer Will Writing" size="xl" content-class="border-0" title-class="text-white" header-class="bg-primary" body-class="pr-30 pl-30" ok-only ok-title="Cancel"
             hide-footer>
      <div class="ribbon success ribbon-top-left">
        <span>50% Off</span>
      </div>
      <b-row>
        <b-col md="6">
          <ul class="list-square pt-20">
            <li>
              <h6>Appoint Executors, Trustees & Guardians</h6>
              <p>Name the people who you want to take charge when the time comes & put money in the hands of trusted friends and family until children are older.</p>
            </li>
            <li>
              <h6>Fully qualified advisors</h6>
              <p>Our advisors are committed to continued professional development and trained to the highest standards.</p>
            </li>
            <li>
              <h6>TSI approved code of practice</h6>
              <p>Our code of practice is approved by the Trading Standards Institute, we always put the needs of our clients first.</p>
            </li>
            <li>
              <h6>Evening appointments available</h6>
              <p>Our advisors are available between 8:30am and 9:00pm Monday to Thursday, 11:00am and 4:00pm Fridays.</p>
            </li>
          </ul>
        </b-col>
        <b-col md="5" offset-md="1">
          <h2>Referral form here...</h2>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>

export default {
  name: 'DiscountedWillWritingCard',
  components: {
  },
  data() {
    return {
      modalId: 'will-writing-card-review-referral-modal'
    }
  }
}
</script>