<template>
  <div >


    <Header
        :title="title"
        :sub-title="subTitle"
        :banner-image-source="require('@/assets/images/trans-banner.png')"
        barInfo1Icon="i-Business-ManWoman"
        barInfo1Text="Refer a friend"
      />

    <div class="pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction />
          <AboutUsSection />
        </b-row>
      </b-container>
    </div>

    <OffersSection class="mt-40" />

    <Reviews class="mt-20" />

  </div>
</template>

<script>
import {helpers} from "@/views/client/dashboard/referral/sections/helpers";
import Introduction from "@/views/client/dashboard/referral/sections/Introduction";
import Header from "@/views/pages/layout/Header";
import Reviews from "@/components/common/other/Reviews";
import AboutUsSection from "@/views/pages/layout/sections/AboutUsSection";

import OffersSection from "@/views/client/dashboard/referral/sections/OffersSection";

export default {
  name: 'Referral',
  // mounted() {
  //   if (this.$route.params.code) {
  //     // clear tokens if logged in
  //     this.$store.commit('removeTokens');
  //     // client one time access via code
  //     this.$store.dispatch('setClientAuthorizationHeader', this.$route.params.code)
  //     this.$store.commit('accessCode', this.$route.params.code)
  //     this.fetch()
  //   }
  // },
  // created() {
  //   this.pageLoadToastMessage()
  // },
  mixins: [helpers],
  components: {
    Introduction,
    Header,
    Reviews,
    AboutUsSection,
    OffersSection,
  },
  data() {
    return {
      data: null
    }
  },
  methods: {
  }
}
</script>
